import { videoCall } from "assets/images";
import {
  Box,
  Button,
  CenterRow,
  Flex,
  Image,
  Input,
  Text,
  TextArea,
} from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useSchoolLocal } from "../useSchoolLocal";
import { Formik } from "formik";
import {
  type Keys,
  talkToAdvisorInitialValues,
  talkToAdvisorValidation,
} from "./formik";
import { FormikError } from "components/shared/FormikError";
import { useApiCall } from "./useApiCall";
import { LoadingDots } from "components/shared/icons";
import { Alert } from "@mui/material";
import { FormSuccessPage } from "../FormSuccessPage/FormSuccessPage";

export const TalkToAdvisor = () => {
  const { direction, isArabic, t } = useSchoolLocal();

  const { callApi, error, loading, success } = useApiCall();
  return (
    <LandingSectionContainer mt={[50]} gap={[20, 20, 20, 50]} height={["auto"]}>
      {success ? (
        <FormSuccessPage />
      ) : (
        <>
          <Flex
            mr={isArabic ? [0] : 0}
            direction={direction}
            flexDirection={["column"]}
            flexGrow={1}
          >
            {error && (
              <Box my={[10]}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Formik
              validationSchema={talkToAdvisorValidation(t)}
              initialValues={talkToAdvisorInitialValues}
              onSubmit={async (values) => {
                callApi("/api/school_landing/reach-out/", "POST", values);
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Flex gap={20} flexDirection="column" width={["100%"]}>
                  <Text mb={[10, 10, 15]} variant="heading1">
                    {t("talkToAdvisor.title")}
                  </Text>

                  <CenterRow width={["100%"]} flexWrap={["wrap"]} gap={[20]}>
                    <Flex flexGrow={1} flexDirection="column" gap={[10]}>
                      <Text>{t("talkToAdvisor.fullName")}*</Text>
                      <Input
                        name="full_name"
                        height={56}
                        required
                        placeholder={t("talkToAdvisor.fullName") as string}
                        onChange={handleChange}
                      />
                      <FormikError<Keys> name="full_name" />
                    </Flex>
                    <Flex flexGrow={1} flexDirection="column" gap={[10]}>
                      <Text>{t("talkToAdvisor.workEmail")}*</Text>
                      <Input
                        height={56}
                        type="email"
                        name="work_email"
                        required
                        placeholder={t("talkToAdvisor.workEmail") as string}
                        onChange={handleChange}
                      />
                      <FormikError<Keys> name="work_email" />
                    </Flex>
                  </CenterRow>
                  <CenterRow width={["100%"]} flexWrap={["wrap"]} gap={[20]}>
                    <Flex flexGrow={1} flexDirection="column" gap={[10]}>
                      <Text>{t("talkToAdvisor.schoolName")}*</Text>
                      <Input
                        name="school_name"
                        height={56}
                        required
                        placeholder={t("talkToAdvisor.schoolName") as string}
                        onChange={handleChange}
                      />
                      <FormikError<Keys> name="school_name" />
                    </Flex>
                    <Flex flexGrow={1} flexDirection="column" gap={[10]}>
                      <Text>{t("talkToAdvisor.jobTitle")}*</Text>
                      <Input
                        name="job_title"
                        height={56}
                        required
                        placeholder={t("talkToAdvisor.jobTitle") as string}
                        onChange={handleChange}
                      />
                      <FormikError<Keys> name="job_title" />
                    </Flex>
                  </CenterRow>
                  <Flex width={["100%"]} flexDirection="column" gap={[10]}>
                    <Text>{t("talkToAdvisor.phoneNumber")}</Text>
                    <Input
                      name="phone_number"
                      height={56}
                      placeholder={t("talkToAdvisor.phoneNumber") as string}
                      onChange={handleChange}
                    />
                  </Flex>
                  <Flex width={["100%"]} flexDirection="column" gap={[10]}>
                    <Text>{t("talkToAdvisor.additionalInfo")}</Text>
                    <TextArea
                      name="additional_info"
                      height={100}
                      placeholder={t("talkToAdvisor.additionalInfo") as string}
                      onChange={handleChange}
                    />
                  </Flex>

                  <Button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    variant="smallCTA"
                    width={["100%", "100%", 100]}
                    display="flex"
                    justifyContent="center"
                    opacity={loading ? 0.7 : 1}
                  >
                    {loading ? (
                      <Box width={20} height={20}>
                        <LoadingDots />
                      </Box>
                    ) : (
                      t("talkToAdvisor.send")
                    )}
                  </Button>
                </Flex>
              )}
            </Formik>
          </Flex>

          <Image src={videoCall} width={["100%", "100%", "80%", "46%"]} />
        </>
      )}
    </LandingSectionContainer>
  );
};
