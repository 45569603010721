import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CenterRow, Flex, Image, Text, CenterColumn } from "components/Core";
import { Box, Divider } from "@mui/material";
import { useLocal } from "hooks/useLocal";
import { useMemo } from "react";
import {
  dr_aisha,
  dr_Ansley,
  dr_Donia,
  dr_ghezlan,
  dr_maryFusco,
  dr_Sandy,
  dr_sawason,
  dr_wael,
} from "assets/images";

const expertPhotos = {
  1: dr_Donia,
  2: dr_Ansley,
  3: dr_Sandy,
  4: dr_sawason,
  5: dr_ghezlan,
  6: dr_aisha,
  7: dr_wael,
  8: dr_maryFusco,
};

export const SliderDoctorsV2 = () => {
  const { isArabic, t, direction } = useLocal();

  const doctors: Array<{
    id: string;
    name: string;
    speciality: string;
    country: string;
    languages: string;
    degrees: string;
    experience: string;
    photo: string;
  }> = useMemo(() => t("experts", { returnObjects: true }), [isArabic]);

  const settings: Settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 1500,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        "& .slick-track": {
          pb: "25px !important",
          display: "flex !important",
          gap: ["5px", "33.9px"],
        },
        "& .slick-slide": {
          width: {
            xs: "100% !important",
            sm: "auto !important",
          },
          "& > div": {
            padding: ["0 2px", "0 10px"],
          },
        },
        width: "100%",
        overflow: "visible", // Change from hidden to visible
      }}
    >
      <Text mb={30} textAlign={["center"]} variant="heading1">
        {t("expertsPage.expertsTitle")}
      </Text>
      <Slider {...settings}>
        {doctors.map(({ ...doctor }) => {
          return (
            <Flex
              direction={direction}
              position="relative"
              flexDirection={["column"]}
              boxShadow="normal"
              bg="white"
              p={3}
              borderRadius={10}
              gap={10}
              width="100%"
              minWidth={[300, 310, 330]}
              // maxWidth={[300, 310, 344]}
              m={["0 auto"]}
              minHeight={isArabic ? 510 : 466}
            >
              <CenterColumn gap={"5px"}>
                <Image
                  draggable={false}
                  style={{
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  width={"100%"}
                  height={250}
                  src={
                    expertPhotos[Number(doctor.id) as keyof typeof expertPhotos]
                  }
                  alt="doctor"
                />
                <Text mt={2} fontSize="18px" fontWeight="bold">
                  {t(doctor.name)}
                </Text>
                <Text variant="light">{t(doctor.speciality)}</Text>
                <Divider sx={{ my: 1 }} />
                <Text variant="body3">{t(doctor.degrees)}</Text>
                <CenterRow gap={10}>
                  <Text variant="body3">{t(doctor.country)}</Text>
                </CenterRow>
                <CenterRow gap={10}>
                  <Text variant="body3">{t(doctor.languages)}</Text>
                </CenterRow>
                <Text fontWeight="bold" color="primary">
                  {t(doctor.experience)}
                </Text>
              </CenterColumn>
            </Flex>
          );
        })}
      </Slider>
    </Box>
  );
};
